<script>
import {useRoute} from "vue-router";
import {computed} from "vue";

export default {
  name: "AppFooter",
  setup() {
    const route = useRoute();
    const routeName = computed(() => route.name);
    const isSelfQualification = computed(() => routeName.value === 'self-qualify');

    return {isSelfQualification}
  }
}
</script>

<template>
  <div :class="['container-fluid', 'footer', 'px-4', {'footer--qualification' : isSelfQualification}]">
    <div
      v-if="isSelfQualification"
      class="container qualification-footer"
    >
      <div class="qualification-footer__top">
        <div class="qualification-footer__top-group qualification-footer__top-group--secondary">
          <a href="/" class="pe-3">{{ $t('Nav.Home') }}</a>
          <a href="/my-quotes" class="pe-3">{{ $t('Nav.My Quotes') }}</a>
          <a href="/contact" class="pe-3">{{ $t('Nav.Contact') }}</a>
          <a href="/profile">{{ $t('Nav.Profile') }}</a>
        </div>
        <div class="qualification-footer__top-group">
          <a href="/terms" >{{ $t('Nav.User Terms') }}</a>
          <a href="/privacy" class="px-3">{{ $t('Nav.Privacy') }}</a>
          <a href="/prize">{{ $t('Nav.Prize') }}</a>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12 col-md">
          <span>
            {{ $t('Footer.Address') }}
          </span>
        </div>
      </div>
    </div>
    <div
      v-else
      class="container"
    >
      <div class="row">
        <div class="col-12 col-md">
          <span>
            {{ $t('Footer.Address') }}
          </span>
        </div>
        <div class="col-12 col-md d-flex order-first order-md-last">
          <div class="col-12 text-center text-md-end pb-md-0 pb-2">
            <a href="/terms">{{ $t('Nav.User Terms') }}</a>
            <a href="/privacy" class="px-3">{{ $t('Nav.Privacy') }}</a>
            <a href="/prize">{{ $t('Nav.Prize') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">

</style>
